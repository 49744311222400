import React, { useReducer, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, Collapse } from '@mui/material'
import Container from '@mui/material/Container';
import backgroundVector from 'src/assets/images/enterprise-vector.png'
import { Button } from "src/templates"
import TextField from '@mui/material/TextField';
import { ADD_INVESTOR_PERU } from 'src/utils/queries'
import Validations from 'src/utils/validations'
import InputAdornment from '@mui/material/InputAdornment';

const validations = new Validations()

const initialState = {
 message: {
  text: '', type: undefined,
 },
 loading: false,
 values: {
  firstname: '',
  lastname: '',
  email: '',
  phonePeru: '',
 },
 errors: {
  error_firstname: false,
  error_lastname: false,
  error_email: false,
  error_phonePeru: false,
 },
}
  
function reducer(state, action) {
 switch (action.type) {
 case 'setError':
  return {
   ...state,
   errors: {
    ...state.errors, ...action.error,
   },
  }
 case 'setState':
  return {
   ...state, ...action.state,
  }
 case 'setValue': {
  return {
   ...state,
   values: {
    ...state.values, ...action.value,
   },
   errors: {
    ...state.errors, ...validations.validateField(action.value),
   },
  }
 }
 default:
  throw new Error()
 }
}

const EnterpriseFormPeru = ({title, body, image, backgroundColor, titleSent, bodySent}) => {

 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
 const md = useMediaQuery(theme.breakpoints.down('md'))

 const styles = {
  paper: {
   border: `2px solid ${theme.palette.primary.main}`,
   borderRadius: "20px",
   minWidth: "320px",
   maxWidth: lg ? "none" : "465px",
   width: lg ? "100%" : "auto",
   background: backgroundColor ? "white" : "transparent"
  },
  allyFormBackground: {
   padding: "40px 0px",
   background: backgroundColor ? `${theme.palette.primary.main}20` : "white",
  },
  title: {
   display: 'inline-block',
  },
  allyFormContainer: {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   flexDirection: !lg ? 'row' : 'column',
   rowGap: 5,
   columnGap: "50px",
  },
  allyFormGraphic: {
   width: !lg ? '42.5%' : '100%',
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'center',
   alignItems: "center",
   columnGap: 2,
  },
  imageContainer: {
   width: "min-content",
  },
  rightImageContainer: {
   '&::before': {
    display: 'block',
    position: 'absolute',
    content: "' '",
    backgroundImage: `url(${backgroundVector})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: "300px",
    height: "80px",
    zIndex: -1,
    marginLeft: "-200px",
   }
  },
  image: {
   width: "100%",
   maxWidth: lg ? "480px" : "auto",
   minWidth: "172px",
  },
  leftImage: {
   marginTop: "200px",
  },
  inputAdornment: {
   "& p": {
    color: theme.palette.text.primary
   }
  }
 }

 const [state, dispatch] = useReducer(reducer, initialState)
 const [isFormSent, setIsFormSent] = useState(false)
 const [errorMessage, setErrorMessage] = useState(null)
 const errors = Object.values(state.errors).some((err) => Boolean(err) === true)

 const sendForm = (event) => {
  event.preventDefault()
  let validateError = null
  let errorsToFix = false
  const tempState = state
  // eslint-disable-next-line array-callback-return
  Object.entries(state.values).map(([keys, values]) => {
   validateError = validations.validateField({
    [keys]: values,
   })
   tempState.errors[`error_${keys}`] = validateError[`error_${keys}`]
   errorsToFix = Object.values(tempState.errors).some((err) => Boolean(err) === true)
  })
  dispatch({
   type: 'setError',
   state: {
    errors: tempState.errors,
   }
  })
  if (errorsToFix === false) {
   setAddInvestorPeru({
    variables: {
     firstname: state.values.firstname,
     lastname: state.values.lastname,
     email: state.values.email,
     phone: "+51-" + state.values.phonePeru.substr(0, 1) + "-" + state.values.phonePeru.substr(1, 4) + "-" + state.values.phonePeru.substr(5, 4) 
    }},
   )
   dispatch({
    type: 'setState',
    state: {
     loading: true,
    },
   })
  }
 }

 const [setAddInvestorPeru] = useMutation(ADD_INVESTOR_PERU,
  {
   onCompleted(data) {
    if(data) {
     setIsFormSent(true)
    }
   },
   onError({ graphQLErrors }) {
    if (graphQLErrors) {
     dispatch({
      type: 'setState',
      state: {
       loading: false,
      },
     })
     for (let err of graphQLErrors) {
      switch (err.extensions.response.status) {
      case 409:
       return setErrorMessage("El correo electrónico ingresado ya se encuentra registrado.")
      default:
       return setErrorMessage("Ha ocurrido un error, por favor inténtalo nuevamente.")
       
      }
     }
    }
   }
  })
 
 return (
  <Box sx={styles.allyFormBackground}>
   <Container sx={styles.allyFormContainer}>
    {/* Left side */}
    <Box sx={styles.allyFormGraphic}>
     <Box component="img" sx={styles.image} src={image} alt=""/>
    </Box>
    {/* Right side */}
    <Box sx={styles.paper}>
     <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: "flex", flexDirection: "column", height: isFormSent ? 250 : (errorMessage ? 708 : 625), padding: md ? "24px" : "48px 64px", justifyContent: "space-between", textAlign: isFormSent ? "center" : "left" }}
     >
      <Typography variant="h3" color="primary.dark" sx={styles.title}>{isFormSent ? titleSent : title}</Typography>
      <Typography variant="body1" sx={{ padding: "16px 0px" }}>{isFormSent ? bodySent : body}</Typography>
      {!isFormSent && (
       <>
        <TextField id="outlined-basic" label="Nombre" name="firstname" variant="outlined" 
         InputLabelProps={{
          style: { color: theme.palette.text.primary },
         }}
         onChange={(e) => dispatch({
          type: 'setValue',
          value: {
           firstname: e.target.value,
          },
         })}
         value={state.values.firstname}
         error={state.errors.error_firstname !== false}
         helperText="Por favor, ingresa tu nombre"
        />
        <TextField id="outlined-basic" label="Apellido" name="lastname" variant="outlined"
         InputLabelProps={{
          style: { color: theme.palette.text.primary },
         }}
         onChange={(e) => dispatch({
          type: 'setValue',
          value: {
           lastname: e.target.value,
          },
         })}
         value={state.values.lastname}
         error={state.errors.error_lastname !== false}
         helperText="Por favor, ingresa tu apellido"
        />
        <TextField id="outlined-basic" label="Correo electrónico" name="email" variant="outlined"
         InputLabelProps={{
          style: { color: theme.palette.text.primary },
         }}
         onChange={(e) => dispatch({
          type: 'setValue',
          value: {
           email: e.target.value,
          },
         })}
         value={state.values.email}
         error={state.errors.error_email !== false}
         helperText="Por favor, ingresa tu correo electrónico"
        />
        <TextField id="outlined-basic" label="Teléfono de contacto" name="phonePeru" variant="outlined"
         InputLabelProps={{
          style: { color: theme.palette.text.primary },
         }}
         InputProps={{
          startAdornment: <InputAdornment sx={styles.inputAdornment} position="start">+51</InputAdornment>,
         }}
         onChange={(e) => dispatch({
          type: 'setValue',
          value: {
           phonePeru: e.target.value,
          },
         })}
         value={state.values.phonePeru}
         error={state.errors.error_phonePeru !== false}
         helperText="Por favor, ingresa tu teléfono"
        />
        <Box sx={{ margin: "0 auto", textAlign: "center" }}>
         <Button
          loading={state.loading}
          disabled={errors}
          variant="contained"
          type="submit"
          action={(event) => sendForm(event)}
         >
            Enviar datos
         </Button>
         {(errorMessage !== null) && (
          <Collapse in={errorMessage !== null} sx={{ marginTop: "24px"}}>
           <Alert
            style={{
             alignItems: 'center',
            }}
            severity={"error"}
           >
            {errorMessage}
           </Alert>
          </Collapse>
         )}
        </Box>
       </>
      )}
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { EnterpriseFormPeru }