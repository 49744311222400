import React from "react"
import { getContent } from 'src/queries';
import { EnterpriseFormPeru } from "src/components/common/EnterpriseFormPeru"
import Box from '@mui/material/Box';

export default function Inversiones() {

 const allyFormContentAreaResults = getContent("ally-form-content-area", process.env.GATSBY_REACT_APP_REGION)

 return (
  <Box mt="70px">
   {process.env.GATSBY_REACT_APP_REGION === 'es-PE' && (
    <EnterpriseFormPeru
     title={"¿Quieres invertir?"}
     body={"Envíanos tus datos de contacto para que conversemos."}
     titleSent={"Datos enviados"}
     bodySent={"Muchas gracias por tu interés en invertir en Cumplo. Te responderemos dentro de 24 horas hábiles. ¡Hablamos pronto!"}
     image={allyFormContentAreaResults?.images[0]?.file?.url}
     items={allyFormContentAreaResults?.icons}
    />
   )}
  </Box>
 )}